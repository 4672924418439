* {
  scrollbar-width: thin;
  scrollbar-color: #aaa #f6f6f6;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #aaa;
  border: 2px solid transparent;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "微軟正黑體", "Microsoft JhengHei",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
